import React from 'react';
import MenuContainer from '../../modules/Menu/MenuContainer';
import {Router} from '@reach/router';
import appConfig from '../../../app-config';
const environment = process.env.GATSBY_ENV;

let path = `/`;

if (environment === 'development') {
  path = '/menu/*';
}

const MenuPage = ({location}) => {
  return (
    <Router>
      <MenuContainer path={path} location={location} />
    </Router>
  );
};

export default MenuPage;
